<template>
	<div v-loading="pageLoading" class="app-warapper">
		<AppHeader />
		<AppMain />
	</div>
</template>
<script>
import AppHeader from './components/AppHeader'
import AppMain from './components/AppMain'
import { mapState } from 'vuex'
export default {
	name: 'Layout',
    components:{
        AppHeader,
        AppMain
	},
	data() {
		return {}
	},
	computed: {
		...mapState({
			pageLoading: state => state.pageLoading,
		})
	},
	watch: {
		'$route': {
			immediate: true,
			handler(newRoute, oldRoute) {
				let passwordExpiry = window.sessionStorage.getItem("passwordExpiry");
				if(window.sessionStorage.getItem("initPassFlag") == "1") {
					this.$confirm(
						"您的密码为初始密码，请尽快修改",
						{
							confirmButtonText: "确定",
							type: "warning",
							closeOnClickModal: false,
							closeOnPressEscape: false,
							showCancelButton: false,
							showClose: false
						}
					)
					.then(() => {
						this.$router.push({
							name: 'editUserEmail'
						})
					})
					.catch(() => {});
					return
				}
				if (passwordExpiry) {
					this.$confirm(
						"您的密码已经超过6个月未修改，请尽快修改",
						{
							confirmButtonText: "确定",
							type: "warning",
							closeOnClickModal: false,
							closeOnPressEscape: false,
							showCancelButton: false,
							showClose: false
						}
					)
					.then(() => {
						this.$router.push({
							name: 'editUserEmail'
						})
					})
					.catch(() => {});
				}
			}
		}
	},
	mounted() {
		this.checkPass();
	},
	methods:{
		checkPass(newRoute, oldRoute) {
			let passwordExpiry = window.sessionStorage.getItem("passwordExpiry");
			if(window.sessionStorage.getItem("initPassFlag") == "1") {
				this.$confirm(
					"您的密码为初始密码，请尽快修改",
					{
						confirmButtonText: "确定",
						type: "warning",
						closeOnClickModal: false,
						closeOnPressEscape: false,
						showCancelButton: false,
						showClose: false
					}
				)
				.then(() => {
					this.$router.push({
						name: 'editUserEmail'
					})
				})
				.catch(() => {});
				return
			}
			if (passwordExpiry) {
				this.$confirm(
					"您的密码已经超过6个月未修改，请尽快修改",
					{
						confirmButtonText: "确定",
						type: "warning",
						closeOnClickModal: false,
						closeOnPressEscape: false,
						showCancelButton: false,
						showClose: false
					}
				)
				.then(() => {
					this.$router.push({
						name: 'editUserEmail'
					})
				})
				.catch(() => {});
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.app-warapper{
	background-image: url('~@/assets/images/background.png');
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: -65px;
}
</style>
