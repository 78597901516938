<template>
	<div class="page edit-page" :style="height">
		<div class="top-wrapper">
			<div class="title"> {{85 > scrollTop ? '查看主题' : ''}} </div>
			<div class="actions">
				<div v-if="replyFlag">
                    <el-button v-if="data.status === 2" plain size="small" :class="data.rclFlag === 0 ? 'accept' : ''"
                        @click="updateRcl" :disabled="data.rclFlag === 1"> {{ data.rclFlag === 0 ? '报告RCL' : '已报告RCL' }}  </el-button>
					<el-button v-if="(data.status === 1 || data.status === 2) && data.receiveFlag === 0 && !isTechMaterial" plain size="small"
						class="accept" @click="receive"> 接受 </el-button>
					<el-button v-if="(data.status === 1 || data.status === 2) && !replying && data.receiveFlag === 1 && !isTechMaterial" plain
						size="small" class="reply" @click="openReplyEditor"> 回复主题 </el-button>
					<!-- <el-button v-if="(data.status === 1 || data.status === 2) && replying && data.receiveFlag === 1"
						type="primary" plain size="small" class="submit" :loading="loading.replying" @click="reply"> 提交
					</el-button> -->
					<el-button v-if="(data.status === 1 || data.status === 2) && data.receiveFlag === 1 && !isTechMaterial" plain size="small"
						class="transfer" @click="transferDialog = true"> 转单 </el-button>
					<el-button v-if="haveClosePermission && (data.status === 1 || data.status === 2)" plain size="small"
						class="close" @click="close"> 关闭 </el-button>
					<el-button v-if="haveSetPublicPrivatePermission && (data.status === 3 && data.publicFlag === 1)" plain size="small"
						class="close" @click="setPrivate"> 转为内部 </el-button>
					<el-button v-if="haveSetPublicPrivatePermission && (data.status === 3 && data.publicFlag === 0)" plain size="small"
						class="accept" @click="setPublic"> 转为公开 </el-button>
					<el-button plain size="small" class="cancel" @click="goBack"> 返回 </el-button>
				</div>
				<div v-if="!replyFlag">
					<el-button type="primary" plain size="small" class="submit" @click="reply">确定</el-button>
					<el-button type="primary" plain size="small" class="submit" @click="handleCancel">取消</el-button>
				</div>
			</div>
		</div>

		<div class="content-wrapper">
			<div class="mail-container pa20 mb15">
				<div class="main-title">
					{{ data.title }}
				</div>
				<div class="attribute-wrapper">
					<el-row :gutter="20">
						<el-col :lg="6" :md="6" :sm="8" :xs="12">
							<div class="attribute-item">
								<div class="attribute-item__label"> 单号 </div>
								<div class="attribute-item__content">
									<span> {{ data.orderNo || '' }} </span>
								</div>
							</div>
						</el-col>
						<el-col :lg="6" :md="6" :sm="8" :xs="12">
							<div class="attribute-item">
								<div class="attribute-item__label"> 资源 </div>
								<div class="attribute-item__content">
									<span> {{ data.sourceName || '' }} </span>
								</div>
							</div>
						</el-col>
						<el-col :lg="6" :md="6" :sm="8" :xs="12">
							<div class="attribute-item">
								<div class="attribute-item__label"> 内容 </div>
								<div class="attribute-item__content">
									<span> {{ data.detailName || '' }} </span>
								</div>
							</div>
						</el-col>
						<el-col :lg="6" :md="6" :sm="8" :xs="12">
							<div class="attribute-item">
								<div class="attribute-item__label"> 产品名称 </div>
								<div class="attribute-item__content">
									<span> {{ data.deviceNames || '' }} </span>
								</div>
							</div>
						</el-col>
						<el-col :lg="6" :md="6" :sm="8" :xs="12">
							<div class="attribute-item">
								<div class="attribute-item__label"> 发件人 </div>
								<div class="attribute-item__content">
									<span> {{ data.pubBy || '' }} </span>
								</div>
							</div>
						</el-col>
						<el-col :lg="6" :md="6" :sm="8" :xs="12">
							<div class="attribute-item">
								<div class="attribute-item__label"> 发件时间 </div>
								<div class="attribute-item__content">
									<span>
										{{ (data.pubTime && $moment(data.pubTime).format('YYYY-MM-DD HH:mm:ss')) || '' }}
									</span>
								</div>
							</div>
						</el-col>
						<el-col v-if="data.closeBy" :lg="6" :md="6" :sm="8" :xs="12">
							<div class="attribute-item">
								<div class="attribute-item__label"> 关闭人 </div>
								<div class="attribute-item__content">
									<span>
										{{ data.closeBy || '' }}
									</span>
								</div>
							</div>
						</el-col>
						<el-col v-if="data.closeBy" :lg="6" :md="6" :sm="8" :xs="12">
							<div class="attribute-item">
								<div class="attribute-item__label"> 关闭时间 </div>
								<div class="attribute-item__content">
									<span>
										{{ (data.closeTimeStr && $moment(data.closeTimeStr).format('YYYY-MM-DD HH:mm:ss')) || '' }}
									</span>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div v-if="!isTechMaterial" class="sendtarget-wrapper">
					<el-row :gutter="20">
						<el-col v-if="sendTargetStr" :span="24">
							<div class="sendtarget-item">
								<div class="sendtarget-item__label min60"> 接收对象 </div>
								<div class="sendtarget-item__content">
									<span> {{ sendTargetStr || '' }} </span>
								</div>
							</div>
						</el-col>
						<el-col v-if="sendUserStr" :span="24">
							<div class="sendtarget-item">
								<div class="sendtarget-item__label min90"> 接收/抄送 用户 </div>
								<div class="sendtarget-item__content">
									<span> {{ sendUserStr || '' }} </span>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div v-if="!isTechMaterial" class="receiver-wrapper">
					<el-table :data="receiveData" v-loading="loading.receiver" size="mini" stripe max-height="217"
						class="table-xmini">
						<el-table-column prop="name" label="接收人" show-overflow-tooltip>
							<template slot-scope="scope">
								<span :class="scope.row.transfer ? 'transfer' : ''"> {{ scope.row.name }} </span>
							</template>
						</el-table-column>
						<el-table-column prop="actionTime" label="接收时间" show-overflow-tooltip>
							<template slot-scope="scope">
								<span :class="scope.row.transfer ? 'transfer' : ''"> {{ scope.row.actionTime }} </span>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="mail-content-wrapper">
					<div class="mail-content-title"> 内容 </div>
					<div class="mail-content-body" v-loading="loading.content">
						<RichTextDisplay :content="subjectContent" class="mail-content-body__display"></RichTextDisplay>
					</div>
				</div>
				<div class="link-wrapper">
					<template v-for="(attachment, index) of data.firmwareList">
						<template v-if="index > 0">
							<span class="info-text" :key="'link-prefix_' + index"> / </span>
							<el-link :key="index" @click="handleLink(attachment.firmwareLink)" type="primary">
								{{ attachment.firmwareName }} </el-link>
						</template>
						<template v-else>
							<el-link :key="index" icon="el-icon-link" @click="handleLink(attachment.firmwareLink)" type="primary">
								{{ attachment.firmwareName }} </el-link>
						</template>
					</template>
				</div>
				<div v-if="data.sysAttachFileList && data.sysAttachFileList.length > 0" class="attach-content-wrapper">
					<div class="attach-content-title"> 附件 </div>
					<div class="attach-content-body">
						<template v-for="(attachment, index) of data.sysAttachFileList">
							<template v-if="index > 0">
								<span class="info-text" :key="'link-prefix_' + index"> / </span>
								<el-link :key="index" @click="handleLink1(attachment.fileAddr)" type="primary">
									{{ attachment.fileName }} </el-link>
							</template>
							<template v-else>
								<el-link :key="index" icon="el-icon-link" @click="handleLink1(attachment.fileAddr)" type="primary">
									{{ attachment.fileName }} </el-link>
							</template>
						</template>
					</div>
				</div>
			</div>

			<div class="reply-container pa20">
				<div class="reply-title"> 更新历史记录 </div>
				<div v-if="replying" class="reply-input-area">
					<RichTextEditor :content.sync="content" placeholder="请输入主题内容"></RichTextEditor>
					<ReplyEditor ref="editor" :fileIds.sync="fileIds"></ReplyEditor>
				</div>
				<template v-if="history.length === 0 && !replying && !loading.history">
					<div class="empty-block">
						<span class="empty-text"> 暂无数据 </span>
					</div>
				</template>
				<div v-loading="loading.history">
					<ReplyDisplay
						v-for="(h, index) of history"
						:key="index"
						:data="h"
						:showReply="showReply"
						@subreply="handleSubReply"
						@deleteReply="handleDeleteReply"
					></ReplyDisplay>
				</div>
			</div>
		</div>

		<el-dialog :visible.sync="privacyDialog" :show-close="false" center width="380px" top="40vh"
			class="privacy-dialog">
			<template slot="title">
				<div class="privacy-title">
					已关闭该主题，是否设置为公开？
				</div>
			</template>
			<div class="privacy-content">
				<div class="privacy-actions">
					<el-button class="privacy-action__private" @click="setPrivacy(0)"> 设为隐私 </el-button>
					<el-button plain class="privacy-action__public" @click="setPrivacy(1)"> 设为公开 </el-button>
				</div>
			</div>
		</el-dialog>

		<TransferDialog :subjectId="this.id" :visible.sync="transferDialog" @success="getDetail"
			@onTransferSuccess="onTransferSuccess"></TransferDialog>
	</div>
</template>

<script>
	import ReplyEditor from '@/components/Reply/ReplyEditor'
	import ReplyDisplay from '@/components/Reply/ReplyDisplay'
	import RichTextDisplay from '@/components/RichText/RichTextDisplay'
	import RichTextEditor from '@/components/RichText/RichTextEditor'
	import TransferDialog from './TransferDialog.vue'
	import {
		debounce
	} from '@/utils/debounce'

	export default {
		components: {
			RichTextDisplay,
			ReplyEditor,
			ReplyDisplay,
			RichTextEditor,
			TransferDialog
		},
		data() {
			return {
				id: '',
				cached: {
					pageNo: 1,
					searchKey: '',
					// areaId: '',
					deviceId: '',
					pubByLike: '',
					deviceNamesLike: '',
					pubTimeStart: '',
					pubTimeEnd: '',
					status: '',
					prop: '',
					order: '',
					searchKey2: '',
					details: ''
				},
				data: {},
				// 详情富文本内容
				subjectContent: '',
				replying: false,
				// 回复内容
				content: '',
				// 回复附件ID列表
				fileIds: [],
				// 回复历史
				history: [],
				loading: {
					replying: false,
					history: false,
					receiver: false,
					content: false
				},
				// 接受人数据
				receivers: [],
				// 隐私|公开设置 dialog
				privacyDialog: false,
				// 转发人选择 dialog
				transferDialog: false,
				height: '',
				scrollTop: 0,
				// 点击回复只显示确定和取消按钮
				replyFlag: true,
				// 回复时重复提交
				replySubmitFlag: false,
				subReplyId: '',
			}
		},
		computed: {
			// 判断是否为当前创建者 或 超级管理员 或 管理员
			// 是 才能 关闭
			haveClosePermission() {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				if (userInfo.superAdminFlag === '1') return true
        if (userInfo.adminFlag === '1') return true

				const userId = userInfo.id
				const createdBy = this.data.createId

				if (userId === createdBy) return true

				return false
			},
            // 判断是否为当前创建者 或 超级管理员
            // 是 才能 (设为隐私 || 设为公开)
            haveSetPublicPrivatePermission() {
                const userInfoStr = window.sessionStorage.getItem('userInfo')
                if (!userInfoStr) return false

                const userInfo = JSON.parse(userInfoStr)
                if (!userInfo) return false
                if (userInfo.superAdminFlag === '1') return true

                const userId = userInfo.id
                const createdBy = this.data.createId

                if (userId === createdBy) return true

                return false
            },
			// 接收人及转单记录数据
			receiveData() {
				const data = []
				for (const receiver of this.receivers) {
					data.push({
						name: receiver.createBy,
						actionTime: (receiver.createTimeStr && this.$moment(receiver.createTimeStr).format(
							'YYYY-MM-DD HH:mm:ss')) || '',
						transfer: false
					})

					// 转单记录
					if (receiver.sendObject) {
						const transfers = []
						// 遍历每次转单
						for (const transferTimeStr of Object.keys(receiver.sendObject)) {
							const transferTime = this.$moment(+transferTimeStr).format('YYYY-MM-DD HH:mm:ss')

							const targets = receiver.sendObject[transferTimeStr]
							// 发送对象名称组合
							const targetNames = targets.map(t => t.sendName)
							const sendNameStr = '转单给 ' + targetNames.join(', ')

							transfers.push({
								name: sendNameStr,
								actionTime: transferTime,
								timeStr: transferTimeStr,
								transfer: true
							})
						}

						transfers.sort((a, b) => a.timeStr - b.timeStr)

						data.push.apply(data, transfers)
					}
				}

				return data
			},
			// 发送对象数据 - 字符串
			sendTargetStr() {
				if (!(this.data && this.data.sendList && this.data.sendList.length > 0)) return ''

				let res = []
				for (const s of this.data.sendList) {
					if (s.sendType === 1) res.push(s.deptAllName)
					if (s.sendType === 2) res.push(s.sendName)
					if (s.sendType === 4) res.push(s.sendName)
				}

				return res.join(', ')
			},
			// 发送用户 - 字符串
			sendUserStr() {
				if (!(this.data && this.data.sendList && this.data.sendList.length > 0)) return ''

				let res = []
				for (const s of this.data.sendList) {
					if (s.sendType === 3) res.push(s.sendName)
				}

				return res.join(', ')
			},
			// 如果是技术报告发布或技术公告发布 则隐藏收件人信息 以及隐藏 接收，回复，转单按钮
			isTechMaterial() {
				// if (this.data.source === '1412319183414824961') return true
				if (this.data.detail === '1452459980631355394' || this.data.detail === '1412319806570958850') return true
				return false
			},
			showReply() {
				if (this.data && (this.data.status === 1 || this.data.status === 2) && this.data.receiveFlag === 1 && !this.isTechMaterial) return true
				return false
			}
		},
		created() {
			// this.getPassAndKey();
			this.height = 'min-height: ' + (window.innerHeight - 180) + 'px'

			this.id = this.$route.query.id
			if (this.id) {
				this.getDetail()
				this.getReceiverList()
				this.getReplyList()
				this.getContent()
			}

			this.cached.pageNo = this.$route.params.pageNo || 1
			this.cached.searchKey = this.$route.params.searchKey || ''
			this.cached.searchKey2 = this.$route.params.searchKey2 || ''
			// this.cached.areaId = this.$route.params.areaId || ''
			this.cached.deviceId = this.$route.params.deviceId || ''
			this.cached.pubByLike = this.$route.params.pubByLike || ''
			this.cached.deviceNamesLike = this.$route.params.deviceNamesLike || ''
			this.cached.pubTimeStart = this.$route.params.pubTimeStart || ''
			this.cached.pubTimeEnd = this.$route.params.pubTimeEnd || ''
			if (this.$route.params.status === 0) {
				this.cached.status = this.$route.params.status
			} else {
				this.cached.status = this.$route.params.status || ''
			}
			this.cached.prop = this.$route.params.prop || ''
			this.cached.order = this.$route.params.order || ''
			this.cached.sources = this.$route.params.sources || ''
			this.cached.details = this.$route.params.details || ''
			this.cached.readFlag = this.$route.params.readFlag || ''
		},
		methods: {
			// 获取免输入密码
			// getPassAndKey() {
			// 	let that = this;
			// 	that.$request.post(
			// 		"getInfoByCode",
			// 		false, {
			// 			code: 'firmwareDownload'
			// 		},
			// 		function (r) {
			// 			that.keyUrl = 'https://' + encodeURIComponent(JSON.parse(r.data.val).user) + ':' +
			// 				encodeURIComponent(JSON.parse(r.data.val).pwd) +
			// 				'@support.ricoh.com';
			// 		}
			// 	)
			// },
			handleLink(url) {
				if((this.data.status === 1 || this.data.status === 2) && this.data.receiveFlag === 0 && !this.isTechMaterial) {
					this.$message.error('请先接受再下载')
					return
				}else{
					let that = this;
					that.$request.get(
						"getDownloadKey",
						true, {},
						function (r) {
							const linkNode = document.createElement('a');
							linkNode.style.display = 'none';
							linkNode.href = r.data.path + '?url='+url+'&key='+r.data.key;
							document.body.appendChild(linkNode);
							linkNode.click();  //模拟在按钮上的一次鼠标单击
							URL.revokeObjectURL(linkNode.href); // 释放URL 对象
							document.body.removeChild(linkNode);
						}
					)
				}
			},
			handleLink1(url) {
				if((this.data.status === 1 || this.data.status === 2) && this.data.receiveFlag === 0 && !this.isTechMaterial) {
					this.$message.error('请先接受再下载')
					return
				}else{
					const linkNode = document.createElement('a');
					linkNode.style.display = 'none';
					linkNode.href = url;
					document.body.appendChild(linkNode);
					linkNode.click();  //模拟在按钮上的一次鼠标单击
					URL.revokeObjectURL(linkNode.href); // 释放URL 对象
					document.body.removeChild(linkNode);
				}
			},
			handleCancel() {
				this.replyFlag = true;
				this.replying = false;
				this.subReplyId = '';
				this.content = '';
			},
			// 获取主题详情
			getDetail() {
				if (!this.id) return

				let that = this
				that.$request.post(
					"dataSubjectInfo",
					true, {
						id: that.id
					},
					function (r) {
						if (r.code == "0") {
							that.data = r.data
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取富文本内容
			getContent() {
				if (!this.id) return

				let that = this
				that.loading.content = true
				that.$request.post(
					"dataSubjectRichText",
					false, {
						id: that.id
					},
					function (r) {
						if (r.code == "0") {
							that.subjectContent = r.data.content
						} else {
							that.$message.error(r.msg)
						}
						that.loading.content = false
					}
				)
			},
			// 获取接收人数据
			getReceiverList() {
				if (!this.id) return

				this.loading.receiver = true
				let that = this
				that.$request.post(
					"dataSubjectReceiveList",
					false, {
						subjectId: that.id
					},
					function (r) {
						if (r.code == "0") {
							that.receivers = r.data
						} else {
							that.$message.error(r.msg);
						}
						that.loading.receiver = false
					}
				)
			},
			// 获取回复数据
			getReplyList() {
				if (!this.id) return

				this.loading.history = true
				let that = this
				that.$request.post(
					"dataSubjectReplyList",
					false, {
						subjectId: that.id
					},
					function (r) {
						if (r.code == "0") {
							// that.history = r.data
							that.history = that.$set(that, 'history', r.data)
						} else {
							that.$message.error(r.msg);
						}
						that.loading.history = false
					}
				)
			},
			// 接受
			receive: debounce(function () {
				let that = this
				that.$request.post(
					"dataSubjectReceiveAdd",
					false, {
						subjectId: that.id,
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success('接受成功')
							that.getReceiverList()
							that.getDetail()
						} else {
							that.$message.error(r.msg);
						}
					}
				)
			}, 500),
			// 提交
			reply: debounce(function () {
				// 防止重复提交
				if(this.replySubmitFlag) {
					return
				}
				this.replySubmitFlag = true;
				this.content = this.content.trim()

				if (this.fileIds.length === 0 && !this.content) {
					this.replying = false
					return
				}

				// if (this.content.length > 1000) {
				// 	this.$message.error('回复字数不能超过1000')
				// 	return
				// }

				this.loading.replying = true

				const data = {
					subjectId: this.id,
					fileIdList: this.fileIds,
					content: this.content
				}

				if (this.subReplyId) {
					Object.assign(data, {
						replyId: this.subReplyId
					})
				}

				let that = this
				that.$request.post(
					"dataSubjectReplyAdd",
					false,
					data,
					function (r) {
						if (r.code == "0") {
							that.getReplyList()
						} else {
							that.$message.error(r.msg);
						}

						that.loading.replying = false
						that.replying = false
						that.replyFlag = true
						setTimeout(() => {
							that.replySubmitFlag = false
						}, 500);
					}
				)
			}, 500),
			// 关闭
			close() {
				this.$confirm('是否关闭当前主题?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					let that = this
					that.$request.post(
						"dataSubjectClose",
						false, {
							id: that.id,
							publicFlag: 0
						},
						function (r) {
							if (r.code == "0") {
								that.getDetail()
								that.privacyDialog = true
							} else {
								that.$message.error(r.msg)
							}
						}
					)
				}).catch(() => {})
			},
			// 隐私|公开 设置
			setPrivacy(privacy) {
				// privacy 0:隐私 1:公开
				let that = this
				that.$request.post(
					"dataSubjectClose",
					false, {
						id: that.id,
						publicFlag: privacy
					},
					function (r) {
						if (r.code == "0") {
							const message = privacy === 0 ? '设为隐私成功' : '设为公开成功'
							that.$message.success(message)
							that.getDetail()
						} else {
							that.$message.error(r.msg)
						}
						that.privacyDialog = false
					},
					function () {
						that.privacyDialog = false
					}
				)
			},
			// 设置公开
			setPublic() {
				this.$confirm('是否将当前主题设为公开?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					this.setPrivacy(1)
				}).catch(() => {})
			},
			// 设置隐私
			setPrivate() {
				this.$confirm('是否将当前主题设为隐私?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					this.setPrivacy(0)
				}).catch(() => {})
			},
            // 通信箱
            updateRcl: debounce(function () {
                let that = this
                that.$request.post(
                    "dataSubjectUpdateRcl",
                    false, {
                        id: that.id,
                    },
                    function (r) {
                        if (r.code == "0") {
                            that.$message.success('操作成功')
                            that.getDetail()
                        } else {
                            that.$message.error(r.msg);
                        }
                    }
                )
            }, 500),
			openReplyEditor() {
				this.replying = true;
				this.replyFlag = false;
				this.subReplyId = '';
				this.content = '';
				this.$nextTick(() => {
					this.$refs['editor'].focus()
				})
			},
			// 转单成功刷新接受记录
			onTransferSuccess() {
				this.getReceiverList()
			},
			goBack() {
				this.$router.push({
					name: this.$route.params.isTech === 1 ? 'technique' : 'mailbox',
					params: {
						pageNo: this.cached.pageNo,
						searchKey: this.cached.searchKey,
						searchKey2: this.cached.searchKey2,
						// areaId: this.cached.areaId,
						deviceId: this.cached.deviceId,
						pubByLike: this.cached.pubByLike,
						deviceNamesLike: this.cached.deviceNamesLike,
						pubTimeStart: this.cached.pubTimeStart,
						pubTimeEnd: this.cached.pubTimeEnd,
						status: this.cached.status,
						prop: this.cached.prop,
						order: this.cached.order,
						readFlag: this.cached.readFlag,
						sources: this.cached.sources,
						details: this.cached.details
					}
				})
			},
			// 处理吸顶时title隐藏
			handleScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			},
			// 处理回复的回复
			handleSubReply(data) {
				let pre = ''
				if (data) {
					this.subReplyId = data.id
					pre += '<p>'
					if (data.createBy) pre += '@' + data.createBy
					if (data.companyName) pre += '/' + data.companyName
					if (data.createTimeStr) {
						pre += '       '
						pre += '<span>'
						pre += this.$moment(data.createTimeStr).format('YYYY-MM-DD HH:mm:ss')
						pre += '</span>'
					}
					pre += '</p>'
					if (pre) pre += '<br/>'
				}

				this.content = pre

				this.replying = true;
				this.replyFlag = false;
				this.$nextTick(() => {
					this.$refs.editor.$el.scrollIntoView({
						behavior: "smooth",
						block: "center"
					})
					this.$refs.editor.focus()
				})
			},
			// 处理回复的删除
			handleDeleteReply(data) {
				console.log('删除回复: ', data)
				this.$confirm('是否删除该回复?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					let that = this
					that.$request.post(
						"dataSubjectReplyDelete",
						false, {
							id: data.id
						},
						function (r) {
							if (r.code == "0") {
								that.getReplyList()
							} else {
								that.$message.error(r.msg)
							}
						}
					)
				}).catch(() => {})
			}
		},
		mounted() {
			window.addEventListener('scroll',this.handleScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll)
		}
	}

</script>

<style lang="scss" scoped>
	.top-wrapper {
		.actions {
			.cancel {
				color: rgba(255, 255, 255, 1);
				background-color: rgba(17, 127, 252, 1);
			}
		}
	}

	.table-xmini {
		/deep/ td {
			padding: 4px 0;
		}

		/deep/ th {
			padding: 4px 0;
		}

		/deep/ thead {
			color: rgba(68, 68, 68, 1);
		}

		.el-table__header {
			tr {
				th {
					background-color: rgba(199, 227, 249, .55);
				}
			}
		}

		/deep/ tr.el-table__row--striped {
			td {
				background: rgba(246, 249, 253, .57);
			}
		}
	}

	.mail-container {
		.main-title {
			padding: 5px 0 15px;
			font-weight: bold;
		}

		.attribute-wrapper {
			.attribute-item {
				display: flex;
				flex-direction: column;
				padding: 10px 0;

				.attribute-item__label {
					color: rgba(68, 68, 68, 1);
					text-align: left;
					vertical-align: middle;
					font-size: 12px;
					line-height: 20px;
					box-sizing: border-box;

					&::after {
						content: ':';
					}
				}

				.attribute-item__content {
					color: rgba(34, 34, 34, 1);
					font-size: 14px;
					line-height: 22px;
					padding: 0;
					box-sizing: border-box;
					min-height: 22px;
					word-break: break-all;
				}
			}
		}

		.sendtarget-wrapper {
			.sendtarget-item {
				display: flex;
				flex-direction: row;
				padding: 10px 0;

				.sendtarget-item__label {
					color: rgba(68, 68, 68, 1);
					text-align: left;
					vertical-align: middle;
					font-size: 12px;
					line-height: 20px;
					box-sizing: border-box;

					&::after {
						content: ':';
					}

					&.min90 {
						min-width: 90px;
					}

					&.min60 {
						min-width: 60px;
					}
				}

				.sendtarget-item__content {
					color: rgba(34, 34, 34, 1);
					font-size: 14px;
					line-height: 22px;
					padding: 0;
					padding-left: 10px;
					box-sizing: border-box;
					min-height: 22px;
					word-break: break-all;
				}
			}
		}

		.receiver-wrapper {
			padding: 10px 0;

			.transfer {
				margin-left: 20px;
			}
		}

		.mail-content-wrapper {
			font-size: 14px;
			padding: 10px 0;

			.mail-content-title {
				&::after {
					content: ':'
				}
			}

			.mail-content-body {
				.mail-content-body__display {
					font-size: 14px;
				}
			}
		}

		.link-wrapper {
			padding: 10px 0;
		}

		.attach-content-wrapper {
			font-size: 14px;
			padding: 10px 0;

			.attach-content-title {
				&::after {
					content: ':'
				}
			}

			.attach-content-body {
				margin-top: 5px;
			}
		}
	}

	.reply-container {
		.reply-title {
			padding: 0 0 15px;

			&::after {
				content: ':'
			}
		}

		.empty-block {
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.empty-text {
				text-align: center;
				line-height: 60px;
				width: 50%;
				color: #909399;
				font-size: 12px;
			}
		}
	}

	.privacy-dialog {
		/deep/ .el-dialog {
			border-radius: 10px;
		}

		.privacy-title {
			padding-top: 15px;
			text-align: center;
			font-size: 20px;
			font-weight: bold;
		}

		.privacy-content {
			padding-top: 15px;

			.privacy-actions {
				display: flex;
				align-items: center;
				justify-content: space-around;

				.privacy-action__private {
					flex-grow: 1;
					border: none;
					color: rgba(16, 129, 253, 1);
					background-color: rgba(17, 127, 252, .2);
				}

				.privacy-action__public {
					flex-grow: 1;
					border: none;
					color: rgba(255, 255, 255, 1);
					background-color: rgba(17, 127, 252, 1);
				}
			}
		}
	}

	.privacy-wrapper {
		position: fixed;
		top: 20vh;
		right: 0;

		.privacy-action__private {
			border: none;
			color: rgba(233, 81, 94, 1);
			background-color: rgba(233, 81, 94, .2);
		}

		.privacy-action__public {
			border: none;
			color: rgba(31, 153, 142, 1);
			background-color: rgba(31, 153, 142, .16);
		}
	}

</style>
